import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '../layouts/DefaultLayout'
import LoginLayout from '../layouts/LoginLayout'
import ProfileLayout from '../layouts/ProfileLayout'
const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/pages/MainPage.vue')
            },
            {
                path: '/categories',
                name: 'Categories',
                component: () => import('@/pages/CategoriesPage.vue')
            },
            {
                path: '/detail',
                name: 'Detail',
                component: () => import('@/pages/DetailPage.vue')
            },
            {
                path: '/search',
                name: 'Search',
                component: () => import('@/pages/SearchPage.vue')
            },
        ],
        scrollBehavior(to) {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                };
            }
            return { top: 0 };
        },
    },
    {
        path: '/login',
        component: LoginLayout,
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('@/pages/LoginPage.vue')
            },

        ],
    },
    {
        path: '/profile',
        component: ProfileLayout,
        children: [
            {
                path: 'info',
                name: 'info',
                component: () => import('@/pages/profile/ProfileInfo.vue')
            },
            {
                path: 'history',
                name: 'history',
                component: () => import('@/pages/profile/ProfileHistory.vue')
            },
            {
                path: 'favorite',
                name: 'favorite',
                component: () => import('@/pages/profile/ProfileFavorite.vue')
            },
            {
                path: 'statistic',
                name: 'statistic',
                component: () => import('@/pages/profile/ProfileStatistic.vue')
            },
            {
                path: 'setting',
                name: 'setting',
                component: () => import('@/pages/profile/ProfileSetting.vue')
            },

        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
