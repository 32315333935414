<template>
    <router-link to="/login" class="login-btn">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1423_8609)">
                <path
                    d="M9.71085 10.9259H8.69758C8.69758 9.21647 7.2638 7.82626 5.50019 7.82626C3.73659 7.82626 2.30331 9.21698 2.30331 10.9259H1.29004C1.29004 8.65715 3.17929 6.81299 5.5007 6.81299C7.8216 6.81299 9.71085 8.65715 9.71085 10.9259Z"
                    fill="white"/>
                <path
                    d="M5.50056 6.48359C3.969 6.48359 2.72217 5.23625 2.72217 3.70468C2.72166 2.17261 3.96849 0.925781 5.50056 0.925781C7.03314 0.925781 8.27997 2.17211 8.27997 3.70468C8.27997 5.23726 7.03314 6.48359 5.50056 6.48359ZM5.50056 1.93906C4.52681 1.93906 3.73494 2.73093 3.73494 3.70468C3.73494 4.67844 4.52681 5.47031 5.50056 5.47031C6.47483 5.47031 7.2667 4.67844 7.2667 3.70468C7.2667 2.73093 6.47381 1.93906 5.50056 1.93906Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1423_8609">
                    <rect width="10" height="10" fill="white" transform="translate(0.5 0.925781)"/>
                </clipPath>
            </defs>
        </svg>
        <span>Signup/Login</span>
    </router-link>
</template>

<script setup>

</script>

<style lang="scss">
</style>